import * as Yup from 'yup';

const EmployeeSchema = Yup.object().shape({
  first_name: Yup.string()
    .required('First name is required')
    .min(3, 'First name must be at least 3 characters'),
  last_name: Yup.string().required('Last name is required'),
  phone_number: Yup.string(),
  employee_code: Yup.string().required('Employee Code is required'),
  address_line_1: Yup.string().required('Address is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string(),
  zip_code: Yup.string().required('Zip Code is required'),
  country: Yup.string().required('Country is required'),
  email: Yup.string().required('Email is required').email('Enter a valid email'),
  emergency_contact_email: Yup.string().email('Enter a valid emergency email'),
  password: Yup.string().required('Password is required').min(8, 'Password must have 8 characters'),
  start_date: Yup.string().required('Start date is required'),
  role_id: Yup.string().required('Role is required'),
  employee_position_id: Yup.string().required('Position is required'),
  office_location_id: Yup.string().required('Office Location is required'),
  contract: Yup.string().required('Contract is required'),
  company_id: Yup.string().required('Company is required'),
});

export default EmployeeSchema;

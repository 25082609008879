import { useState, useEffect } from 'react';
import { Button, Row, Col, Badge, Card, CardHeader, Input } from 'reactstrap';
import { Plus, Eye, Trash2 } from 'react-feather';
import Select from 'react-select';
import DataTable from 'react-data-table-component';
import { Link, useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import {DataTransformer, getScreenPermissions} from "../../utility/Utils";
import {useAuth, useCompanies, useLoader, useSelectedCompany} from "../../hooks";
import {ConfirmationModal, showErrorMessage, showSuccessMessage} from "../../components";
import AddTeamModal from "./AddTeamModal";
import {Network, Urls} from "../../apiConfiguration";
import {NavRoutes} from "../../common";



const EmployeeLeaveQuota = () => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [projectManagers, setProjectManagers] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [Teams, setTeams] = useState([]);
  const [apiCall, setApiCall] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const { setLoading } = useLoader();
  const { user } = useAuth();
  const { companies } = useCompanies();
  const { company, setCompany } = useSelectedCompany();
  const [searchValues, setSearchValues] = useState({
    keyword: '',
    office_location_id: '',
  });
  const [permissions, _] = useState(getScreenPermissions(user?.permissions, 'teams'));
  const navigate = useNavigate();

  const getTeam = async (obj, currentPage) => {
    setLoading(true);
    const response = await Network.post(
      `${Urls.getTeamByFilters(company)}?page=${currentPage + 1}`,
      obj
    );
    setLoading(false);
    if (!response.ok) return showErrorMessage(response.data.message);
    setTeams(response.data.teams);
    setTotalPages(response.data.total_pages);
  };

  useEffect(() => {
    getTeam(searchValues, currentPage);
  }, [apiCall, currentPage, company]);

  useEffect(() => {
    getDropDownData();
  }, []);

  const DeleteTeam = async () => {
    const response = await Network.delete(`${Urls.manageTeams(company)}/${selectedTeam.id}`);
    if (!response.ok) return showErrorMessage(response.data.message);
    showSuccessMessage(response.data.message);
    setApiCall((prevVal) => !prevVal);
    setDeleteModal(false);
  };

  const columns = [
    {
      name: 'Employee Name',
      selector: (row) => row.team_code,
    },
    {
      name: 'Leave Name',
      selector: (row) => row.team_name,
    },

    {
      name: 'Office Location ',
      selector: (row) => row.project_manager_name,
    },
    {
      name: 'Status',
      selector: (row) => row.is_active,
      cell: (row) => {
        return (
          <Badge pill color={row.is_active ? 'success' : 'danger'}>
            {row.is_active ? 'Active' : 'Inactive'}
          </Badge>
        );
      },
    },
    {
      name: 'Actions',
      cell: (row) => {
        return (
          <div>
            <Eye
              className='cursor-pointer'
              onClick={() =>
                navigate(`${NavRoutes.viewTeam}/${row.id}`, {
                  state: {
                    editable: permissions.find((item) => item === 'edit') ? true : false,
                  },
                })
              }
            />

            {!!permissions.find((item) => item === 'delete') && (
              <Trash2
                color='red'
                className='ms-1 cursor-pointer'
                onClick={() => {
                  setSelectedTeam(row);
                  setDeleteModal(true);
                }}
              />
            )}
          </div>
        );
      },
    },
  ];

  const CustomPagination = () => {
    const handlePagination = (page) => {
      setCurrentPage(page.selected);
    };

    return (
      <ReactPaginate
        previousLabel=''
        nextLabel=''
        onPageChange={(page) => handlePagination(page)}
        pageCount={totalPages}
        breakLabel='...'
        pageRangeDisplayed={2}
        marginPagesDisplayed={2}
        forcePage={currentPage}
        activeClassName='active'
        pageClassName='page-item'
        breakClassName='page-item'
        nextLinkClassName='page-link'
        pageLinkClassName='page-link'
        breakLinkClassName='page-link'
        previousLinkClassName='page-link'
        nextClassName='page-item next-item'
        previousClassName='page-item prev-item'
        containerClassName='pagination react-paginate separated-pagination pagination-sm justify-content-end pe-1 mt-1'
      />
    );
  };

  const getDropDownData = async () => {
    setLoading(true);
    const response = await Network.get(Urls.getProjectManagers);
    setLoading(false);
    if (!response.ok) return showErrorMessage('Failed to load dropdown data');
    setProjectManagers(DataTransformer(response.data, 'name', 'id'));
  };

  return (
    <>
      <Row>
        {!!permissions.find((item) => item === 'add') && (
          <Col className='offset-md-9'>
            <Button onClick={() => setShowAddModal(true)} className='w-100' color='primary'>
              <Plus size={15} />
              <span className='ms-1'>Add Team</span>
            </Button>
          </Col>
        )}
      </Row>
      <Card className='mt-2'>
        <CardHeader>
          <Row className='w-100'>
            <Col md={3} className='mt-2 mt-md-0'>
              <Select
                options={companies}
                placeholder='Select Leave Type'
                isDisabled={user?.changeCompany}
                value={companies.find((item) => item.value === company)}
                onChange={({ value }) => {
                  setCompany(value);
                }}
              />
            </Col>
            <Col md={4} className='mt-2 mt-md-0'>
              <Select
                isClearable
                options={projectManagers}
                placeholder='Select Office Location'
                value={projectManagers.find(
                  (item) => item.value === searchValues.project_manager_id
                )}
                onChange={(value) => {
                  let obj = {};
                  if (value === null) {
                    obj = { ...searchValues, project_manager_id: '' };
                  } else {
                    obj = { ...searchValues, project_manager_id: value.value };
                  }
                  setSearchValues(obj);
                  setCurrentPage(0);
                  getTeam(obj, 0);
                }}
              />
            </Col>
            <Col md={4} className='mt-2 mt-md-0'>
              <Select
                  isClearable
                  options={projectManagers}
                  placeholder='Select Date'
                  value={projectManagers.find(
                      (item) => item.value === searchValues.project_manager_id
                  )}
                  onChange={(value) => {
                    let obj = {};
                    if (value === null) {
                      obj = { ...searchValues, project_manager_id: '' };
                    } else {
                      obj = { ...searchValues, project_manager_id: value.value };
                    }
                    setSearchValues(obj);
                    setCurrentPage(0);
                    getTeam(obj, 0);
                  }}
              />
            </Col>

          </Row>
        </CardHeader>
        <div className='react-dataTable'>
          <DataTable
            noHeader
            columns={columns}
            data={Teams}
            pagination
            paginationComponent={CustomPagination}
          />
        </div>
      </Card>
      <AddTeamModal
        key={showAddModal}
        setShow={setShowAddModal}
        show={showAddModal}
        setApiCall={setApiCall}
      />
      <ConfirmationModal
        btnText='Delete'
        description='Are you sure you want to delete this team?'
        fn={DeleteTeam}
        mode='Warning'
        setShowModal={setDeleteModal}
        showModal={deleteModal}
      />
    </>
  );
};

export default EmployeeLeaveQuota;

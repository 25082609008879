import * as Yup from "yup";

const PartnerSchema = Yup.object().shape({
  partner_code: Yup.string().required("Partner code is required"),
  partner_name: Yup.string().required("Partner name is required"),
  partner_short_name: Yup.string().required("Partner short name is required"),
  notes: Yup.string().required("Notes is required"),
  // address_line_1: Yup.string().required("Address is required"),
  // city: Yup.string().required("City is required"),
  // state: Yup.string().required("State is required"),
  // zip_code: Yup.string().required("Zip code is required"),
  // country: Yup.string().required("Country is required"),
  // mobile_phone: Yup.number().required("Mobile phone is required"),
  // work_phone: Yup.number().required("Work phone is required"),
  // emergency_contact_name: Yup.string().required(
  //   "Emergency contact name is required"
  // ),
  // emergency_contact_number: Yup.string().required(
  //   "Emergency contact number is required"
  // ),
});

export default PartnerSchema;

import * as Yup from "yup";

const CompanySchema = Yup.object().shape({
  company_name: Yup.string().required("Company Name is required"),
  lookup_report_sequence: Yup.number(),
  notes: Yup.string(),
  first_name: Yup.string()
    .required('First Name is required')
    .min(3, 'First name must be at least 3 characters'),
  last_name: Yup.string().required('Last Name is required'),
  address_line_1: Yup.string().required('Address is required'),
  phone_number: Yup.string().required('Phone number is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  zip_code: Yup.string().required('Zip code is required'),
  country: Yup.string().required('Country is required'),
  email: Yup.string().required('Email is required').email('Enter a valid email'),
  emergency_contact_email: Yup.string()
    .required("Emergency email is required")
    .email("Enter a valid email"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters"),
  start_date: Yup.string().required("Start date is required"),
  office_location_id: Yup.string().required("Office location is required"),
});

export default CompanySchema;

import * as Yup from 'yup';

const EditEmployeeSchema = Yup.object().shape({
  first_name: Yup.string()
    .required('First name is required')
    .min(3, 'First name must be at least 3 characters'),
  last_name: Yup.string().required('Last name is required'),
  email: Yup.string().required('Email is required').email('Enter a valid email'),
  phone_number: Yup.string(),
  address_line_1: Yup.string().required('Address is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string(),
  country: Yup.string().required('Country is required'),
  zip_code: Yup.string().required('Zip code is required'),
  emergency_contact_email: Yup.string().email('Enter a valid email'),
  start_date: Yup.string().required('Date is required'),
  employee_position_id: Yup.string().required('Position is required'),
  role_id: Yup.string().required('Role is required'),
  office_location_id: Yup.string().required('Office locaion is required').nullable(),
  company_id: Yup.string().required('Company is required'),
  employee_code: Yup.string().required('Employee Code is required'),
});

export default EditEmployeeSchema;

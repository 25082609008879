import * as Yup from "yup";

const EmployeeCourseSchema = Yup.object().shape({
  course_id: Yup.string().required("Course is required"),
  exam_maximum_score: Yup.string().required("Exam maximum score is required"),
  project_maximum_score: Yup.string().required(
    "Project maximum score is required"
  ),
  exam_score: Yup.string().required("Exam score is required"),
  project_score: Yup.string().required("Project score is required"),
  exam_date: Yup.string().required("Exam date is required"),
  is_completed: Yup.string().required("Status is required"),
  completion_date: Yup.string().required("Completion date is required"),
});

export default EmployeeCourseSchema;

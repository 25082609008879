import * as Yup from "yup";

const WorkCodeSchema = Yup.object().shape({
  work_category_id: Yup.string().required("Cateogory is required").nullable(),
  work_classification_id: Yup.string()
    .required("Classification is required")
    .nullable(),
  work_code: Yup.string().required("Code is required"),
  lookup_report_sequence: Yup.number(),
  thermometer_raw_offset: Yup.number().required(
    "Thermometer offset is required"
  ),
  qa_code: Yup.string().required("QA code is required"),
  is_extra: Yup.string().required("Base or Extra is required"),
  is_chargeable: Yup.string().required("Chargeable is required"),
  notes: Yup.string().required("Work code details are required"),
  company_id: Yup.string().required("Company is required"),
});

export default WorkCodeSchema;

import * as Yup from "yup";

const EmployeeWagesSchema = Yup.object().shape({
  wage_gross: Yup.string().required("Wage gross is required"),
  wage_net: Yup.string().required("Wage net score is required"),
  currency: Yup.string().required("Currency is required"),
  hourly_rate: Yup.string().required("Hourly rate is required"),
  date: Yup.string().required("Date is required"),
});

export default EmployeeWagesSchema;

import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "reactstrap";
import Select from "react-select";

import StatsHorizontal from "@components/widgets/stats/StatsHorizontal";
import { Network, Urls } from "../../apiConfiguration";
import { showErrorMessage } from "../../components";
import { useAuth, useCompanies, useLoader } from "../../hooks";
import Table from "./Table";
import WorkCategoriesModal from "./OfficeLeaveModal";
import { getScreenPermissions } from "../../utility/Utils";
import OfficeLeaveModal from "./OfficeLeaveModal";

const OfficeLeaves = () => {
  const { user } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [data, setData] = useState([]);
  const [call, setCall] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { setLoading } = useLoader();
  const [company, setCompany] = useState(user?.company_id);
  const { companies } = useCompanies();
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    setPermissions(getScreenPermissions(user?.permissions, "workCategories"));
  }, []);

  useEffect(() => {
      getLeaveTypes(currentPage);
  }, [call, company]);

  useEffect(() => {
    setData(
      leaveTypes?.slice((currentPage - 1) * 10, (currentPage - 1) * 10 + 10)
    );
  }, [currentPage]);

  const getLeaveTypes = async (currentPage) => {
    setLoading(true);
    const response = await Network.get(`${Urls.manageOfficeLeaveTypes(company)}?page=${currentPage}`);
    setLoading(false);

    if (!response.ok) return showErrorMessage(response.data.message);
    const { data } = response.data;
    console.log(data);
    setLeaveTypes(data);
    setTotalPages(Math.ceil(data.length / 10));
    const newData = data?.slice(
      (currentPage - 1) * 10,
      (currentPage - 1) * 10 + 10
    );

    setData(newData);
  };

  return (
    <div>
      {/*<Row>*/}
      {/*  <Col className="mt-2" md={4} sm={12}>*/}
      {/*    <StatsHorizontal*/}
      {/*      color="primary"*/}
      {/*      statTitle="Total Leaves Types"*/}
      {/*      renderStats={*/}
      {/*        <h3 className="fw-bolder mb-75">{leaveTypes.length}</h3>*/}
      {/*      }*/}
      {/*    />*/}
      {/*      <StatsHorizontal*/}
      {/*          color="primary"*/}
      {/*          statTitle="Total Leaves Types"*/}
      {/*          renderStats={*/}
      {/*              <h3 className="fw-bolder mb-75">{leaveTypes.length}</h3>*/}
      {/*          }*/}
      {/*      />*/}
      {/*  </Col>*/}
      {/*</Row>*/}
      <Row className="my-1">
          <Col md={4}>
              <Select
                  placeholder="Company Name"
                  options={companies}
                  isDisabled={user?.changeCompany}
                  value={companies.find((item) => item.value === company)}
                  onChange={({ value }) => {
                      setCurrentPage(1);
                      setTotalPages(0);
                      setCompany(value);
                  }}
              />
          </Col>
        {!!permissions.find((item) => item === "add") && (
          <Col className="offset-md-5 mt-2 mt-md-0" md={3}>
            <Button
              onClick={() => setShowModal(true)}
              color="primary"
              className="w-100"
            >
              Add Office Leaves
            </Button>
          </Col>
        )}
      </Row>
      <Card className="mt-2">
        <Table
          data={data}
          setCheck={setCall}
          totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setData={setLeaveTypes}
          companies={companies}
          permissions={permissions}
        />
      </Card>
      <OfficeLeaveModal
        setCheck={setCall}
        show={showModal}
        setShow={setShowModal}
        companies={companies}
      />
    </div>
  );
};

export default OfficeLeaves;

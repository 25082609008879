import { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalHeader, Row, Col, Label, Input, Button } from 'reactstrap';
import Select from 'react-select';
import { useFormik } from 'formik';
import { Network, Urls } from '../../apiConfiguration';
import { showErrorMessage, showSuccessMessage } from '../../components';
import { DataTransformer } from '../../utility/Utils';
import { useAuth, useCompanies, useLoader, useSelectedCompany } from '../../hooks';
import * as Yup from 'yup';

const AddTeamModal = ({ setShow, show, setApiCall }) => {
  const { user } = useAuth();
  const initialValues = {
    team_name: '',
    team_code: '',
    notes: '',
    group_manager_id: '',
    project_manager_id: '',
    is_active: 1,
    employees: [],
    company_id: user?.company_id,
  };
  const [projectManagers, setProjectManagers] = useState([]);
  const [groupManagers, setGroupManagers] = useState([]);
  const [employees, setEmployees] = useState([]);
  const { setLoading } = useLoader();
  const { companies } = useCompanies();
  const { company } = useSelectedCompany();

  const onSubmit = async (obj) => {
    setLoading(true);
    const data = { ...obj, employees: employees };
    const response = await Network.post(Urls.manageTeams(obj.company_id), data);
    setLoading(false);
    if (!response.ok) return showErrorMessage(response.data.message);
    showSuccessMessage(response.data.message);
    setApiCall((prevVal) => !prevVal);
    setShow(false);
  };

  const getProjectManager = async () => {
    setLoading(true);
    const response = await Network.get(Urls.getProjectManagers);
    setLoading(false);
    if (!response.ok) return showErrorMessage(response.data.message);
    setProjectManagers(DataTransformer(response.data, 'name', 'id'));
  };

  const getGroupManager = async () => {
    const obj = {
      employee_position: 'Group Manager',
    };
    setLoading(true);
    const response = await Network.post(Urls.getEmployeePosition, obj);
    setLoading(false);
    if (!response.ok) return showErrorMessage(response.data.message);
    setGroupManagers(DataTransformer(response.data, 'name', 'id'));
  };
  useEffect(() => {
    getProjectManager();
    getGroupManager();
  }, []);

  const { values, handleChange, handleSubmit, errors, touched, handleBlur } = useFormik({
    initialValues,
    onSubmit,
    validationSchema: Yup.object().shape({
      team_code: Yup.string().required('Team Code is required'),
      team_name: Yup.string().required('Team Name is required'),
      group_manager_id: Yup.string().required('Group Manager is required'),
      project_manager_id: Yup.string().required('Project Manager is required'),
    }),
  });

  const ErrorMessage = ({ name }) => {
    if (!touched[name]) return null;
    return <div className='text-danger fst-italic fw-bold'>{errors[name]}</div>;
  };

  return (
    <Modal isOpen={show} toggle={() => setShow(false)} className='modal-dialog-centered '>
      <ModalHeader className='bg-white' toggle={() => setShow(false)} />
      <ModalBody className='px-md-5'>
        <h2 className='m-auto text-center'>Add Team</h2>
        <Row className='mt-1'>
          <Col tag='div' className='mb-md-1' md={4} sm={4}>
            <Label>Team Code*</Label>
            <Input
              name='team_code'
              value={values.team_code}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <ErrorMessage name='team_code' />
          </Col>

          <Col tag='div' className='mb-md-1' md={8} sm={8}>
            <Label>Team Name*</Label>
            <Input
              name='team_name'
              value={values.team_name}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <ErrorMessage name='team_name' />
          </Col>
          <Col tag='div' className='mb-md-1' md={12} sm={12}>
            <Label>Company*</Label>
            <Select
              options={companies}
              isDisabled={user?.changeCompany}
              value={companies.find((item) => item.value === values.company_id)}
              onChange={({ value }) => {
                const e = { target: { name: 'company_id', value } };
                handleChange(e);
              }}
              onBlur={handleBlur}
            />
            <ErrorMessage name='company_id' />
          </Col>
        </Row>
        <Row>
          <Col tag='div' className='mb-md-1' md={6} sm={12}>
            <Label>Group Manager*</Label>
            <Select
              name='group_manager_id'
              options={groupManagers}
              className='react-select'
              onBlur={handleBlur}
              classNamePrefix='select'
              value={groupManagers.find((item) => item.value === values.group_manager_id)}
              onChange={({ value }) => {
                let event = { target: { name: 'group_manager_id', value } };
                handleChange(event);
              }}
            />
            <ErrorMessage name='group_manager_id' />
          </Col>
          <Col tag='div' className='mb-md-1' md={6} sm={12}>
            <Label>Project Manager*</Label>
            <Select
              name='project_manager_id'
              options={projectManagers}
              className='react-select'
              classNamePrefix='select'
              onBlur={handleBlur}
              value={projectManagers.find((item) => item.value === values.project_manager_id)}
              onChange={({ value }) => {
                let event = { target: { name: 'project_manager_id', value } };
                handleChange(event);
              }}
            />
            <ErrorMessage name='project_manager_id' />
          </Col>
        </Row>
        <Row className='mt-1'>
          <Col md='12'>
            <Label>Notes</Label>
            <Input name='notes' type='textarea' value={values.notes} onChange={handleChange} />
          </Col>
          <Col md={12} className='mt-2 d-flex align-items-center'>
            <Label>Active</Label>
            <div className='form-switch form-check-success ms-1'>
              <Input
                type='switch'
                id='switch-success'
                name='is_active'
                value={values.is_active}
                onChange={(e) => {
                  const value = e.target.checked;
                  let event = { target: { name: 'is_active', value } };
                  handleChange(event);
                }}
                defaultChecked
              />
            </div>
          </Col>
        </Row>
        <div className='d-flex my-2 justify-content-center'>
          <Button className='me-1' color='primary' onClick={handleSubmit}>
            Save
          </Button>
          <Button onClick={() => setShow(false)} className='ms-1' color='danger'>
            Cancel
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AddTeamModal;

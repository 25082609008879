import * as Yup from 'yup';

const ProjectSchema = Yup.object().shape({
  client_id: Yup.string().required('Customer is required'),
  // project_manager_id: Yup.string().required("Project manager is required"),
  project_code: Yup.string().required('Project code is required'),
  project_name: Yup.string()
    .required('Project name is required')
    .max(25, 'Project name cannot be more than 25 characters'),
  // notes: Yup.string().required("Project description is required"),
  project_zones: Yup.string().required('Project zone is required'),
  budget_hours: Yup.number().required('Project budget hours is required'),
  project_blocks: Yup.string().required('Project Blocks  are required'),
  // budget_cost: Yup.string().required("Project budget cost is required"),
  // project_contract_value: Yup.string().required(
  //   "Project contract value is required"
  // ),
  // project_end_date: Yup.string().required("Project end date is required"),
  project_start_date: Yup.string().required('Project start date is required'),
  miscellaneous_budget: Yup.number().min(
    0,
    'Miscellaneous budget must be greater than or equal to 0'
  ),
});

export default ProjectSchema;

import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import { Trash2, Edit2, Eye } from 'react-feather';
import ReactPaginate from 'react-paginate';
import { Badge } from 'reactstrap';

import { ConfirmationModal, showErrorMessage, showSuccessMessage } from '../../components';
import { useLoader } from '../../hooks';
import { Network, Urls } from '../../apiConfiguration';
import WorkCategoriesModal from './OfficeLeaveModal';
import { useSelectedCompany } from '../../hooks';
import OfficeLeaveModal from "./OfficeLeaveModal";

const Table = ({
  data,
  setCheck,
  currentPage,
  setCurrentPage,
  totalPages,
  companies,
  permissions,
}) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [category, setCategory] = useState();
  const { setLoading } = useLoader();
  const { company } = useSelectedCompany();

  const columns = [
    {
      name: 'Office Location',
      selector: (row) => row.officeLocation,
      sortable: true,
    },
    {
      name: 'Leave type',
      selector: (row) => row.leaveType,
      sortable: true,
    },
    {
      name: 'Quota',
      selector: (row) => row.quota,
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row) => row.is_active,
      cell: (row) => {
        return (
          <Badge pill color={row.is_active ? 'success' : 'danger'}>
            {row.is_active ? 'Active' : 'inActive'}
          </Badge>
        );
      },
    },

    {
      name: 'Actions',
      cell: (row) => {
        return (
          <div className='d-flex justify-content-evenly align-content-center'>
            <Eye
              onClick={() => {
                setCategory(row);
                setViewModal(true);
              }}
              className='cursor-pointer me-1'
              size={20}
            />
            {!!permissions.find((item) => item === 'edit') && (
              <Edit2
                onClick={() => {
                  setCategory(row);
                  setShowEditModal(true);
                }}
                className='cursor-pointer me-1'
                size={20}
              />
            )}

            {!!permissions.find((item) => item === 'delete') && (
              <Trash2
                onClick={() => {
                  setCategory(row);
                  setShowDeleteModal(true);
                }}
                color='red'
                className='cursor-pointer'
                size={20}
              />
            )}
          </div>
        );
      },
    },
  ];

  const handleDelete = async () => {
    setLoading(true);
    const response = await Network.delete(`${Urls.manageWorkCategories(company)}/${category.id}`);
    setLoading(false);
    if (!response.ok) return showErrorMessage(response.data.message);
    const length = data.length - 1;

    showSuccessMessage(response.data.message);
    setCheck((prev) => !prev);
    setShowDeleteModal(false);
  };

  const handlePagination = (page) => {
    setCurrentPage(page.selected + 1);
  };

  const CustomPagination = () => {
    return (
      <ReactPaginate
        previousLabel={''}
        nextLabel={''}
        breakLabel='...'
        pageCount={totalPages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        activeClassName='active'
        forcePage={currentPage !== 0 ? currentPage - 1 : 0}
        onPageChange={(page) => handlePagination(page)}
        pageClassName='page-item'
        breakClassName='page-item'
        nextLinkClassName='page-link'
        pageLinkClassName='page-link'
        breakLinkClassName='page-link'
        previousLinkClassName='page-link'
        nextClassName='page-item next-item'
        previousClassName='page-item prev-item'
        containerClassName={
          'pagination react-paginate separated-pagination pagination-sm justify-content-end pe-1 mt-1'
        }
      />
    );
  };

  return (
    <>
      <div className='react-dataTable'>
        <DataTable
          columns={columns}
          data={data}
          pagination
          paginationComponent={CustomPagination}
        />
      </div>
      {showEditModal && (
        <OfficeLeaveModal
          mode='Edit'
          show={showEditModal}
          setShow={setShowEditModal}
          setCheck={setCheck}
          initialValue={category}
          companies={companies}
        />
      )}
      {viewModal && (
        <OfficeLeaveModal
          mode='View'
          show={viewModal}
          setShow={setViewModal}
          setCheck={setCheck}
          initialValue={category}
          companies={companies}
        />
      )}
      <ConfirmationModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        mode='Warning'
        description='Are you sure you want to delete this work category?'
        btnText='Delete'
        fn={handleDelete}
      />
    </>
  );
};

export default Table;
